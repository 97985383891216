import styled from 'styled-components';

export const TableWrapper = styled.div`
  border-radius: 0;
  margin-bottom: 1rem;
  .rdt_TableHead {
    .rdt_TableHeadRow {
      background-color: #fff;
      border-top: 1px solid;
      border-color: #ebe9f1;
      min-height: 0;

      .rdt_TableCol {
        padding: 1rem 0.5rem;
        .rdt_TableCol_Sortable {
          color: #6e6b7b;
          font-weight: bold;
          font-size: 0.9rem;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        padding: 0.5rem 0.5rem;
      }
    }
  }

  .filter-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;

    @media (max-width: 500px) {
      display: block;
    }
  }

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 500px) {
      justify-content: left;
    }
  }

  .entry-options {
    @media (max-width: 500px) {
      margin-bottom: 1rem;
    }
  }

  .form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;

    appearance: none;
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;

    height: 2.142rem !important;
    font-size: 0.857rem !important;
    line-height: 1 !important;
    color: #6e6b7b;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  input:focus,
  select:focus {
    border-color: #7367f0 !important;
    outline: none;
  }
`;

export const Label = styled.label`
  color: #5e5873;
  font-size: 0.857rem;
`;

export const Select = styled.select`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 5rem !important;

  background-position: calc(100% - 12px) 10px, calc(100% - 20px) 13px, 100% 0;
  padding-right: 1.5rem;
  background-image: url('/assets/chevron-down.svg');
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
`;

export const Input = styled.input`
  margin-left: 0.5rem;
  padding: 0.188rem 0.857rem;
  border-radius: 0.357rem;
  max-width: 220px;

  &:focus {
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
`;

export const PaginationWrapper = styled.div`
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end !important;

  .pagination {
    &.react-paginate {
      display: flex;
      list-style: none;
      margin-top: 1rem;
      font-size: 0.85rem;

      a {
        line-height: 1.3;
        padding: 0.843rem 0.745rem;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6e6b7b;
        background-color: #f3f2f7;
      }

      .active {
        font-weight: 500;
        background-color: #f3f2f7;
        a {
          border-radius: 5rem;
          background-color: #7367f0;
          color: #fff;
        }
      }

      li {
        cursor: pointer;
        min-width: 2rem;
      }

      .prev-item {
        margin-right: 0.3571rem;
      }

      li:first-child .page-link,
      li:last-child .page-link {
        border-radius: 50%;
      }
    }
  }
  li:first-child {
    a {
      &:after {
        background-image: url('/assets/chevron-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        color: #6e6b7b;
        width: 5px;
        height: 10px;
        content: '';
      }
    }
  }
  li:last-child {
    a {
      &:after {
        background-image: url('/assets/chevron-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        color: #6e6b7b;
        width: 5px;
        height: 10px;
        content: '';
      }
    }
  }
`;

export const SubRowWrapper = styled.div`
  background-color: #f3f2f7;

  .evidence-wrapper {
    border-bottom: 2px solid #d7d5e0;
    padding: 1.5rem 1rem 1rem 3.5rem;

    &:hover {
      background-color: #e9e8ed;
    }
  }

  h5 {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  .evidence-row {
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    align-content: center;
    word-break: break-all;

    .context {
      font-style: italic;
      white-space: pre-line;
    }

    p {
      font-size: 0.85rem;
    }

    a {
      color: inherit;
      margin: auto;
    }
  }

  .source-details {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    h4 {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

    .encounterDate {
      margin: 1rem 0;

      h5 {
        font-size: 0.95rem;
      }

      span {
        font-weight: 400;
      }
    }

    .context {
      margin-top: 1rem;
      white-space: pre-line;
    }

    p {
      font-size: 0.85rem;
      font-style: italic;
      max-width: 800px;
    }
  }

  .source-link {
    margin: 1.5rem 0;
    font-size: 1.05rem;
  }

  h6 {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
  }

  .source-file {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }

  .external-link-icon {
    width: 15px;
    height: 15px;
    margin-left: 0.25rem;
  }

  .evidence-detail-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    align-content: center;
    word-break: break-all;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: black;
  }
`;

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { Button, Spinner } from '../../components';
import Footer from '../../layout/Footer';
import { DocumentContext } from '../../context/DocumentProvider';

// import { mockEhrRecord } from 'data/mockData';
// import sampleDocument from '../../data/MDP_HCC_V1.xml';

import parseDocument from '../../lib/utils/helper/parseDocument';
import GlobalStyles from '../../styles/Global.style';

import logo from '../../assets/images/logo/mdportals-logo.png';

import { FileUploadWrapper, Logo } from './FileUpload.style';

const removeBrands = (str) => {
  if (typeof str !== 'string') return '';

  return str.replace(/Health Gorilla Inc./g, '');
};

export const FileUpload = ({ ehrRecord }) => {
  const [fileValue, setFileValue] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);

  const { setCurrentDocument, setRawDocument, setRecordData } =
    useContext(DocumentContext);

  const onDrop = useCallback((file) => {
    setFileLoading(true);

    const reader = new FileReader();

    reader.readAsDataURL(file[0]);

    reader.onload = () => {
      const b64Url = reader.result;

      const b64String = b64Url.substring(b64Url.indexOf('base64,') + 7);

      let encodedXml = decodeURIComponent(
        atob(b64String)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
          .trim()
      );

      encodedXml = removeBrands(encodedXml);

      setFileValue(encodedXml);
      setRawDocument(encodedXml);
    };

    reader.onerror = (err) => {
      setFileError(err);
    };
  }, []);

  useEffect(async () => {
    // // Test data for development
    // setFileValue(sampleDocument);

    if (ehrRecord) {
      setFileLoading(true);
      console.log('[FileUpload] ehrRecord: ', ehrRecord);
      const parsedDocument = await parseDocument(ehrRecord.recordXml);

      const rawDocumentClean = removeBrands(ehrRecord.recordXml);

      setRawDocument(rawDocumentClean);
      setRecordData(ehrRecord.recordData);
      setCurrentDocument(parsedDocument);
    } else if (fileValue) {
      setFileLoading(true);
      if (fileValue.startsWith('/static')) {
        try {
          const res = await axios.get(fileValue, {
            'Content-Type': 'application/xml'
          });

          setRawDocument(res.data);
        } catch (error) {
          console.dir(error);
          return;
        }
      }

      const document = await parseDocument(fileValue);
      setCurrentDocument(document);
    }
  }, [fileValue, setFileValue, setCurrentDocument, ehrRecord]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: '.xml'
  });

  return (
    <>
      <GlobalStyles />
      <>
        {fileError ? (
          <p>Error while uploading File: {fileError}</p>
        ) : (
          <>
            {fileLoading ? (
              <Spinner />
            ) : (
              <>
                <FileUploadWrapper>
                  <div>
                    <Logo src={logo} />
                    <div className="upload-container" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Click to select a CDA file (.xml file type only)</p>
                    </div>
                    {/* <div className="sample-container">
                      or
                      <Button
                        text="View Sample CDA"
                        onClick={() => {
                          setFileValue(sampleDocumentExtended);
                          setFileLoading(true);
                        }}
                      />
                    </div> */}
                  </div>
                </FileUploadWrapper>
                <Footer />
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

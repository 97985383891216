const createTable = ($, tableNode) => {
  if (!$ || !tableNode) return;

  const table = $(tableNode);

  let columns = [];
  let data = [];

  if ($(table).attr('class') === 'hcc') {
    // Only 1 column -> Category
    columns.push('Category');
  } else {
    // Build Header Columns
    $(table)
      .find('> thead > tr')
      .each((i, row) => {
        $(row)
          .find('th')
          .each((j, cell) => {
            const col = $(cell).text().trim();

            if (col) {
              columns.push(col);
            } else {
              columns.push(' ');
            }
          });
      });
  }

  // Recognize HCC Table
  if ($(table).attr('class') === 'hcc') {
    $(table)
      .find('tbody > tr')
      .each((i, row) => {
        const newRow = {};
        let col;
        let nestedContent = [];

        $(row)
          .find('th[class=category]')
          .each((j, cell) => {
            col = $(cell).text().trim();
            newRow['Category'] = col;
          });

        // Find Nested Content
        $(row)
          .find('tr[class=nestedContent]')
          .each((j, subRow) => {
            $(subRow)
              .find('tr[class=evidence]')
              .each((k, cell) => {
                const context = $(cell).find('td[class=context]').text().trim();
                const location = $(cell).find('td[linkHtml]').text().trim();
                const ref = $(cell).find('td[linkHtml]').attr('linkHtml');

                const details = {};
                const evidenceDetails = $(cell).find(
                  'tr[class=evidenceDetails]'
                );
                $(evidenceDetails)
                  .find('tr[class=evidenceDetail]')
                  .each((i, evidenceDetail) => {
                    const key = $(evidenceDetail)
                      .find('td[class=key]')
                      .text()
                      .trim();
                    const value = $(evidenceDetail)
                      .find('td[class=value]')
                      .text()
                      .trim();

                    const attachment = $(evidenceDetail)
                      .find('td[class=value]')
                      .attr('linkHtml');
                    const searchString = $(evidenceDetail)
                      .find('td[class=value]')
                      .attr('ID');

                    details[key] = {
                      value: value,
                      attachment: attachment,
                      searchString: searchString
                    };
                  });

                nestedContent.push({ context, location, ref, details });
              });

            newRow['nestedContent'] = nestedContent;
          });

        data.push(newRow);
      });

    return {
      columns,
      data
    };
  } else {
    // Build Table Body Data
    $(table)
      .find('tbody > tr')
      .each((i, row) => {
        const newRow = {};

        $(row)
          .find('td, th')
          .each((j, cell) => {
            let col;

            $(cell).find('br').replaceWith('\n');

            col = $(cell).text().trim();

            newRow[columns[j]] = col;
          });
        data.push(newRow);
      });

    return { columns, data };
  }
};

export default createTable;

import styled from 'styled-components';

export const AutoCompleteWrapper = styled.div`
  width: 100%;

  input {
    background: none;
    box-shadow: none;
    width: 100%;
    outline: none;
    height: auto;
    transition: all 0.3s ease-out;
    border: none;
    padding: 1.5rem 3.6rem;
    line-height: 16px;
    border-radius: 0.357rem;
    font-size: 1rem;
    font-weight: 400;
    color: #6e6b7b;
    overflow: visible;
    margin: 0;
  }
`;

export const ListWrapper = styled.div`
  position: absolute;
  background: #fff;
  list-style: none;
  padding-left: 0;
  border-radius: 0.5rem;
  max-height: 450px;
  height: auto;
  width: 100%;
  position: absolute;
  margin-top: 0.5rem;
  list-style-type: none;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  overflow-x: hidden;
  overflow-y: scroll;

  .suggestion-item {
    padding: 0.9rem 1rem;
    color: #6e6b7b;

    a {
      color: #6e6b7b;
    }

    &.suggestion-category {
      font-size: 0.85rem;
      color: $text-muted;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      margin-top: 0.75rem;
      margin-bottom: 0;
      line-height: 1.5;
      display: flex;
      align-items: center;
      text-decoration: underline;
    }

    .highlighted {
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    &.active,
    &:hover:not(.suggestion-category):not(.no-result) {
      background-color: #f1f1f1;
      color: #6e6b7b;
      cursor: pointer;
    }

    &.no-result {
      display: flex;
      align-items: center;

      span {
        margin-left: 0.5rem;
      }
    }
  }
`;

import * as R from 'ramda';

const createSearchData = (body, attachments) => {
  let searchData = [];

  body.map((section) => {
    let sectionGroup = {
      groupTitle: '',
      data: []
    };

    const sectionTitle = section.title;

    sectionGroup['groupTitle'] = sectionTitle;

    section.data.map((d) => {
      if (
        d.type === 'paragraph' ||
        d.type === 'raw' ||
        d.type === 'caption' ||
        d.type === 'footnote'
      ) {
        sectionGroup['data'].push({ section: sectionTitle, text: d.content });
      } else if (d.type === 'table') {
        d.content.data.map((row, rowIndex) => {
          const tableRow = row.nestedContent
            ? R.omit(['nestedContent'], row)
            : row;

          const rowId = `${sectionTitle}-${rowIndex}`;
          const text = Object.values(tableRow).join(' ');

          sectionGroup.data.push({
            rowId: rowId,
            text: text
          });
        });
      }
    });

    searchData.push(sectionGroup);
  });

  // Section Titles
  const sectionsData = {
    groupTitle: 'Sections',
    data: []
  };
  body.map((section) => sectionsData['data'].push(section.title));
  searchData.push(sectionsData);

  const attachmentsData = {
    groupTitle: 'Attachments',
    data: []
  };
  attachments.map((att) => attachmentsData['data'].push(att.title));
  searchData.push(attachmentsData);

  return searchData;
};

export default createSearchData;

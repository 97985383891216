import styled from 'styled-components';

export const AttachedDocsWrapper = styled.div`
  height: 100%;

  h3 {
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .attachment-list {
    margin-bottom: 1.5rem;
    overflow-y: scroll;
    height: 90%;
  }

  .attachment-viewer {
    height: 100%;
  }

  iframe {
    position: relative;
    width: 100%;
    height: 80%;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  width: auto;
`;

export const ListWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  width: 100%;
  margin-top: 0.5rem;
  transform: translate3d(0px, 45px, 0px);
  border-radius: 0.358rem;
  box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
  padding: 0.5rem 0;
  margin: 0 0 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 41, 47, 0.05);
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
`;

export const ListItem = styled.a`
  background: transparent;
  cursor: pointer;
  width: 100%;
  padding: 0.65rem 1.28rem;
  clear: both;
  text-align: inherit;
  background-color: transparent;
  border: 0;
  font-weight: 400;
  color: #6e6b7b;
  white-space: wrap;
  display: flex;

  &:hover {
    background-color: rgba(115, 103, 240, 0.12);
  }
`;
export const ListItemWrapper = styled.div`
  background-color: transparent;
  color: #7367f0;
  display: flex;
  align-items: center;
  border: 1px solid #7367f0;
  padding: 0.7rem 1rem;
  border-radius: 0.358rem;
  margin: 1rem 0;
  transition: all 0.5s ease;
  cursor: pointer;
  white-space: wrap;
  overflow: hidden;
  width: 100%;
  word-break: break-all;

  span {
    margin-left: 1rem;
  }

  img {
    width: 2rem;
    height: 2rem;
    user-select: none;
  }

  &:hover {
    background: #f8f8f8;
  }
`;

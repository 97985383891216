import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { scroller } from 'react-scroll';
import queryString from 'query-string';
import xpath from 'xpath';
import cheerio from 'cheerio';

import Layout from '../../layout';
import { DocumentHeader } from '../../components';
import BodySection from '../CDAMainViewer/BodySection';
import useScrollToTop from '../../lib/utils/hooks/useScrollToTop';

import GlobalStyles from '../../styles/Global.style';

export const XMLViewer = ({ data, rawData, documentTitle }) => {
  const history = useHistory();
  const [rowsToExport, setRowsToExport] = useState([]);
  const [resetRows, setResetRows] = useState(false);
  useScrollToTop();

  const setSearchParams = (section, suggestion) => {
    if (section !== 'Sections') {
      const regex = /[\(\)']+/g;
      const selectorArr = suggestion.replace(regex, '|').split('|');
      const selector = selectorArr[selectorArr.length - 2];

      const searchString = suggestion;

      const params = queryString.parse(history.location.search);

      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete('section');
      queryParams.delete('selector');
      queryParams.delete('searchString');
      queryParams.delete('search');

      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString()
      });

      history.replace({
        pathname: history.location.pathname,
        search: params.attachment
          ? `${history.location.search}&section=${section}&selector=${selector}&searchString=${searchString}`
          : `?section=${section}&selector=${selector}&searchString=${searchString}`
      });
    }
  };

  function isNumeric(str) {
    if (typeof str != 'string') return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const convertXpathToSearchValue = (xpathString) => {
    console.log('[convertXpathToSearchValue] xpathString: ', xpathString);
    if (!xpathString || typeof xpathString !== 'string') return;

    const xmlDocumentDom = new DOMParser().parseFromString(rawData, 'text/xml');

    const select = xpath.useNamespaces({ ns1: 'urn:hl7-org:v3' });

    // Example:
    // From: (//section[code[@code='11450-4']]/entry)[12]/act/entryRelationship[@typeCode='SUBJ']/observation/value
    // To: (//ns1:section[ns1:code[@code='11450-4']]/ns1:entry)[12]/ns1:act/ns1:entryRelationship[@typeCode='SUBJ']/ns1:observation/ns1:value

    const xPathNameSpaceAfterDoubleSlash = xpathString.replace(
      /(\/\/)/g,
      '//ns1:'
    );

    let xPathNamespaceAfterEverySlash = '';
    for (let i = 0; i < xPathNameSpaceAfterDoubleSlash.length; i++) {
      const match = xPathNameSpaceAfterDoubleSlash[i].match(/(\/)/);
      if (
        match &&
        xPathNameSpaceAfterDoubleSlash[i - 1] !== '/' &&
        xPathNameSpaceAfterDoubleSlash[i + 1] !== '/'
      ) {
        xPathNamespaceAfterEverySlash += '/ns1:';
      } else {
        xPathNamespaceAfterEverySlash += xPathNameSpaceAfterDoubleSlash[i];
      }
    }

    let xPathNamespaceAfterBracket = '';
    for (let i = 0; i < xPathNamespaceAfterEverySlash.length; i++) {
      const match = xPathNamespaceAfterEverySlash[i].match(/\[/);
      if (
        match &&
        xPathNamespaceAfterEverySlash[i + 1] !== '@' &&
        !isNumeric(xPathNamespaceAfterEverySlash[i + 1])
      ) {
        xPathNamespaceAfterBracket += '[ns1:';
      } else {
        xPathNamespaceAfterBracket += xPathNamespaceAfterEverySlash[i];
      }
    }

    const finalXpathString = xPathNamespaceAfterBracket;

    console.log('finalXpathString: ', finalXpathString);

    const foundNodes = select(finalXpathString, xmlDocumentDom);

    console.log('foundNodes: ', foundNodes);

    let searchString;

    if (foundNodes.length) {
      const valueString = foundNodes[0].outerHTML;

      console.log('valueString: ', valueString);

      const valueNode = cheerio.load(valueString, {
        xml: { xmlMode: true }
      });

      let referenceValue = valueNode('reference').attr('value');

      console.log('referenceValue: ', referenceValue);

      if (referenceValue) {
        if (referenceValue.startsWith('#')) {
          referenceValue = referenceValue.slice(1);
        }
        console.log(
          '[convertXpathToSearchValue] referenceValue: ',
          referenceValue
        );

        const clinicalDocument = cheerio.load(rawData, {
          xml: { xmlMode: true }
        });

        searchString = clinicalDocument(`[ID=${referenceValue}]`).text();
        console.log('[convertXpathToSearchValue] searchString: ', searchString);
      } else {
        const translationValue = valueNode('translation').attr('displayName');
        const valueAttr = valueNode('value').attr('displayName');
        searchString = translationValue ? translationValue : valueAttr;
      }

      return searchString;
    }
  };

  useEffect(() => {
    const params = parse(history.location.search);

    if (params.section) {
      scroller.scrollTo(params.section);
    }

    if (params.search) {
      const xpathString = params.search;

      const searchValue = convertXpathToSearchValue(xpathString);

      if (searchValue) {
        let filteredData = {};

        data.searchData.forEach((suggestion) => {
          filteredData[suggestion.groupTitle] = [];

          const sortData = suggestion.data
            .filter((i) => {
              if (typeof i === 'string') {
                const startCondition = i
                    .toLowerCase()
                    .startsWith(searchValue.toLowerCase()),
                  includeCondition = i
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());

                if (startCondition) {
                  return startCondition;
                } else if (!startCondition && includeCondition) {
                  return includeCondition;
                } else {
                  return null;
                }
              }

              return null;
            })
            .slice(0, 1);

          filteredData[suggestion.groupTitle].push(...sortData);
        });

        Object.keys(filteredData).some((category, i) => {
          if (filteredData[category].length) {
            setSearchParams(category, filteredData[category][0]);
            scroller.scrollTo(category, { smooth: true, duration: 1000 });
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }, []);

  if (!data || !rawData) {
    history.push({
      pathname: '/',
      state: { from: history.location.pathname }
    });
  }

  const { header, body } = data;

  const resetRowsSelection = () => {
    setRowsToExport([]);

    setResetRows(true);

    setTimeout(() => {
      setResetRows(false);
    }, 500);
  };

  return (
    <>
      <GlobalStyles />
      <Layout
        currentDocument={data}
        rowsToExport={rowsToExport}
        resetRowsSelection={resetRowsSelection}
        rawDocument={rawData}
        documentTitle={documentTitle}
      >
        <DocumentHeader documentHeaderData={header} />
        {body &&
          body.map((section, i) => (
            <BodySection
              key={i}
              section={section}
              setRowsToExport={setRowsToExport}
              resetRows={resetRows}
              currentDocument={data}
              rawDocument={rawData}
            />
          ))}
      </Layout>
    </>
  );
};

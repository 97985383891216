import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: 1.5rem;

  h2 {
    font-weight: 500;
    color: #636363;
    line-height: 1.2;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }

  table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
    column-gap: 2rem;

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  tr {
    display: grid;
    grid-template-columns: 200px auto;
    background: transparent !important;
    border: none;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 1rem;
    }
  }

  svg {
    margin-right: 0.4rem;
  }

  td {
    background: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .header-key {
    font-weight: 400;
    font-size: 0.8rem;
  }

  .header-content {
    font-weight: 500;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.1rem;
    }
  }
`;

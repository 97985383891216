import React from 'react';
import { Element } from 'react-scroll';
import { Download } from 'react-feather';
import fileDownload from 'js-file-download';
import { exportSection } from '../../lib/utils/helper/export';

import { Button, Text, Table, TableV2 } from '../../components';

import { Section } from '../../styles/Global.style';

const BodySection = ({
  section,
  setRowsToExport,
  resetRows,
  currentDocument,
  rawDocument
}) => {
  const downloadSection = (document, title) => {
    let exportedSectionString;

    exportedSectionString = exportSection(rawDocument, title);

    const patientName = currentDocument.header.find(
      (el) => el.key === 'Patient'
    ).value;

    const fileName = `${patientName}_` + title.split(' ').join('_');

    const blob = new Blob([exportedSectionString], { type: 'text/xml' });

    fileDownload(blob, `${fileName}.xml`);
  };

  return (
    <Element name={section.title}>
      <Section>
        <div className="section-title">
          <h4>{section.title}</h4>
          <Button
            text={`Export ${section.title}`}
            icon={<Download />}
            className="flex grey small"
            onClick={() => downloadSection(rawDocument, section.title)}
          />
        </div>
        <div className="section-body">
          {section
            ? section.data.map((el, key) => {
                if (
                  el.type === 'raw' ||
                  el.type === 'paragraph' ||
                  el.type === 'caption' ||
                  el.type === 'footnote'
                ) {
                  return <Text key={key}>{el.content}</Text>;
                } else if (
                  el.type === 'table' &&
                  el.content &&
                  el.content.data &&
                  el.content.data.length
                ) {
                  const isHccTable = el.content.data[0].nestedContent
                    ? true
                    : false;

                  return (
                    <>
                      {isHccTable ? (
                        <Table
                          key={key}
                          columns={el.content.columns}
                          data={el.content.data}
                          sectionTitle={section.title}
                          setRowsToExport={setRowsToExport}
                          resetRows={resetRows}
                        />
                      ) : (
                        <TableV2
                          key={key}
                          columns={el.content.columns}
                          data={el.content.data}
                          sectionTitle={section.title}
                          setRowsToExport={setRowsToExport}
                          resetRows={resetRows}
                        />
                      )}
                    </>
                  );
                }
              })
            : null}
        </div>
      </Section>
    </Element>
  );
};

export default BodySection;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

import { PDFViewer, XMLViewer, RTFViewer } from '../../container';
import parseDocument from '../../lib/utils/helper/parseDocument';

import { Spinner } from '../../components';

import { DocumentContext } from '../../context/DocumentProvider';

export const AttachmentViewer = ({ getPatientAttachment }) => {
  const [attachment, setAttachment] = useState(null);
  const [page, setPage] = useState(null);
  const history = useHistory();

  const { currentDocument } = useContext(DocumentContext);

  const isBase64 = (str) => {
    try {
      window.atob(str);

      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(async () => {
    if (currentDocument) {
      const params = queryString.parse(history.location.search);

      let docAttachment;

      if (
        params.attachment &&
        params.attachment.startsWith('http') &&
        getPatientAttachment
      ) {
        const mediaType =
          params.type === 'pdf' ? 'application/pdf' : 'text/xml';

        let data = await getPatientAttachment(params.attachment);
        console.log('[AttachmentViewer] data:::::: ', data);

        if (mediaType === 'text/xml') {
          const xmlDataRes = await axios.get(data);
          data = xmlDataRes.data;
        }

        const title =
          params && params.title ? `Attachment: ${params.title}` : 'Attachment';

        docAttachment = {
          mediaType: mediaType,
          data: data,
          title: title
        };
      } else {
        docAttachment = currentDocument.attachments.find(
          (att) => att.id === params.attachment
        );
      }

      console.log('docAttachment: ', docAttachment);

      if (docAttachment) {
        if (docAttachment.mediaType === 'application/pdf') {
          if (params.page) {
            setPage(parseInt(params.page));
          }

          console.log('[PDF] docAttachment: ', docAttachment);

          setAttachment(docAttachment);
        } else if (docAttachment.mediaType === 'text/xml') {
          const xmlData = docAttachment.data;
          const isB64 = isBase64(xmlData);

          let xmlString;

          if (isB64) {
            xmlString = window.atob(xmlData);
          } else {
            xmlString = xmlData;
          }

          const xmlDoc = await parseDocument(xmlString);

          setAttachment({
            mediaType: 'text/xml',
            data: xmlDoc,
            rawData: xmlString,
            title: docAttachment.title
          });
        } else if (docAttachment.mediaType === 'text/rtf') {
          setAttachment({
            mediaType: 'text/rtf',
            data: docAttachment.data,
            title: docAttachment.title
          });
        }
      }
    }
  }, [history.location.search]);

  return (
    <>
      {attachment ? (
        <>
          {attachment.mediaType === 'application/pdf' ? (
            <PDFViewer data={attachment.data} page={page} />
          ) : attachment.mediaType === 'text/xml' ? (
            <XMLViewer
              documentTitle={attachment.title}
              data={attachment.data}
              rawData={attachment.rawData}
            />
          ) : attachment.mediaType === 'text/rtf' ? (
            <RTFViewer data={attachment.data} />
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

import styled from 'styled-components';

export const TableWrapper = styled.table`
  margin-bottom: 1rem;

  thead {
    tr {
      background-color: #fff;
      border-color: #ebe9f1;
      min-height: 0;
    }

    th {
      text-align: left;
      padding: 1rem 0.5rem;
      color: #6e6b7b;
      font-weight: bold;
      font-size: 0.9rem;
      letter-spacing: 0.5px;
      width: 100%;
      position: relative;

      border-right: 1px solid rgba(0, 0, 0, 0.04);
      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        right: 0;
        background: rgba(0, 0, 0, 0.12);
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
        touch-action: none;

        &.isResizing {
          background: #7367f0;
        }
      }
    }
  }

  tbody {
    td {
      color: black;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 1px solid rgba(0, 0, 0, 0.04);
      padding: 0.5rem 0.5rem;
      white-space: pre-line;
      word-break: break-word;
      align-items: center;
      min-width: 100px;
      font-size: 0.87rem;

      :last-child {
        border-right: 0;
      }
    }

    tr {
      &.isMarked {
        background-color: #7367f0;
        td {
          color: white;
        }
      }

      &.selected {
        background-color: #e3f2fd;
      }
    }
  }
`;

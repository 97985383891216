import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import * as R from 'ramda';
import _ from 'lodash';

// import Logo from '../../assets/images/logo/mdportals-logo.png';

const globalStyles = StyleSheet.create({
  body: {
    padding: 10
  },
  img: {
    marginBottom: 20,
    width: 125
  }
});

const Section = ({ sectionTitle, rows }) => {
  const colNum = Object.keys(R.omit(['nestedContent'], rows[0])).length;
  const colWidth = 100 / colNum;

  const tableStyles = StyleSheet.create({
    sectionTitle: {
      marginBottom: 10
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginBottom: 20
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableColHeader: {
      width: `${colWidth}%`,
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      wordBreak: 'break-all'
    },
    tableCol: {
      width: `${colWidth}%`,
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      wordBreak: 'break-all'
    },
    tableCellHeader: {
      margin: 'auto',
      margin: 5,
      fontSize: 12,
      fontWeight: 500,
      wordBreak: 'break-all'
    },
    tableCell: {
      margin: 'auto',
      margin: 5,
      fontSize: 10,
      wordBreak: 'break-all'
    }
  });

  return (
    <>
      <View>
        <Text style={tableStyles.sectionTitle}>{sectionTitle}</Text>
      </View>
      <View style={tableStyles.table}>
        {/* Table Header */}
        <View style={tableStyles.tableRow}>
          {Object.keys(rows[0]).map((key) => {
            return key !== 'nestedContent' ? (
              <View style={tableStyles.tableColHeader}>
                <Text style={tableStyles.tableCellHeader}>{key}</Text>
              </View>
            ) : null;
          })}
        </View>
        {/* Table Body */}
        {rows.map((row) => {
          return (
            <View style={tableStyles.tableRow}>
              {Object.keys(row).map((key) =>
                typeof row[key] === 'string' ? (
                  <View style={tableStyles.tableCol}>
                    <Text style={tableStyles.tableCell}>{row[key]}</Text>
                  </View>
                ) : null
              )}
            </View>
          );
        })}
      </View>
    </>
  );
};

const headerStyles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '33%'
  },
  tableCol: {
    width: '33%'
  },
  tableCellHeader: {
    margin: 'auto',
    margin: 5,
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 400
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 500
  }
});

const Header = ({ data }) => {
  const colNum = 3;

  const headerData = data.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / colNum);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  for (const row of headerData) {
    if (row.length === 2) {
      row.push({ key: '', value: '' });
    }
    if (row.length === 1) {
      row.push({ key: '', value: '' });
      row.push({ key: '', value: '' });
    }
  }

  return (
    <View style={headerStyles.table}>
      {headerData.map((row) => (
        <View style={headerStyles.tableRow}>
          {row.map((col) => (
            <View style={headerStyles.tableColHeader}>
              <Text style={headerStyles.tableCellHeader}>{col.key}</Text>
              <Text style={headerStyles.tableCell}>
                {col.key === 'Date of Birth' ||
                col.key === 'Created At' ||
                col.key === 'Patient ID' ||
                col.key === 'Document ID'
                  ? col.value
                  : _.startCase(col.value)}
              </Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export const CdaPDF = ({ documentHeader, exportedRows }) => {
  console.log('[CdaPDF] documentHeader: ', documentHeader);

  return (
    <Document>
      <Page style={globalStyles.body}>
        {/* <Image src={Logo} style={globalStyles.img} /> */}
        <Header data={documentHeader} />
        {exportedRows.map((section) => (
          <Section
            documentHeader={documentHeader}
            sectionTitle={section.sectionTitle}
            rows={section.rows}
          />
        ))}
      </Page>
    </Document>
  );
};

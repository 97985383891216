import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IFrame from 'react-iframe';
import axios from 'axios';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'react-feather';

import { Button } from '../../components';

import useScrollToTop from '../../lib/utils/hooks/useScrollToTop';
import { pageTransition } from '../../lib/animation/variants';

import { MenuWrapper, PDFWrapper } from './PDFViewer.style';

export const PDFViewer = ({ data, page }) => {
  useScrollToTop();

  const [blobUrl, setBlobUrl] = useState(null);

  const history = useHistory();

  if (!data) {
    history.push({ pathname: '/', state: { from: history.location.pathname } });
  }

  const createBlobUrl = (data) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  };

  useEffect(() => {
    let url;
    if (typeof data === 'string' && data.startsWith('http')) {
      // const _url = data;
      // const parsedResult = new DOMParser().parseFromString(_url, 'text/html');
      // const parsedUrl = parsedResult.documentElement.textContent;
      // url = parsedUrl;
      url = data;
    } else {
      url = createBlobUrl(data);
    }

    setBlobUrl(url);
  }, []);

  return (
    <motion.div>
      <MenuWrapper>
        <Button
          text="Go Back"
          icon={<ChevronLeft />}
          onClick={() => {
            window.history.back();
          }}
        />
      </MenuWrapper>
      <PDFWrapper>
        {blobUrl ? (
          <IFrame src={`${blobUrl}${page ? `#page=${page}` : ''}`} />
        ) : null}
      </PDFWrapper>
    </motion.div>
  );
};

import React from 'react';
import _ from 'lodash';

import { Icon } from '../../components';
import { Section } from '../../styles/Global.style';
import { HeaderWrapper } from './DocumentHeader.style';

export const DocumentHeader = ({ documentHeaderData }) => {
  return (
    <>
      <Section>
        <HeaderWrapper>
          <div className="header-content">
            <table>
              {documentHeaderData &&
                documentHeaderData.map((el, i) => (
                  <tr key={i}>
                    {el.key === 'Date of Birth' ||
                    el.key === 'Created At' ||
                    el.key === 'Patient ID' ||
                    el.key === 'Document ID' ? (
                      <>
                        <td className="header-key">
                          <Icon title={el.key} />
                          {el.key}
                        </td>
                        <td>{el.value}</td>
                      </>
                    ) : (
                      <>
                        <td className="header-key">
                          <Icon title={el.key} />
                          {el.key}
                        </td>
                        <td>{_.startCase(el.value)}</td>
                      </>
                    )}
                  </tr>
                ))}
            </table>
          </div>
        </HeaderWrapper>
      </Section>
    </>
  );
};

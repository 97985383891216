import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .loading {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .loading:after {
    content: ' ';
    display: block;
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid rgba(121, 97, 249, 1);
    border-color: rgba(121, 97, 249, 1) transparent rgba(121, 97, 249, 1)
      transparent;
    animation: loading 1.2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

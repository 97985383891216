import React from 'react';

import { FooterWrapper } from './Layout.style';
import logoSmall from '../assets/images/logo/mdportals-logo-small.png';

const Footer = () => {
  return (
    <FooterWrapper>
      <ul>
        <li>&copy; 2021 MDPortals Inc.</li>
        <li>
          <img src={logoSmall} alt="MDPortals Logo" width="30" height="30" />
        </li>
      </ul>
    </FooterWrapper>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { CDAMainViewer, AttachmentViewer } from './container';

const Main = ({
  ehrRecord,
  requestPatientRecord,
  isNewRecordAvailable,
  getPatientAttachment,
  exportCDAAsPDF,
  exportCDAAsPDFLoading,
  setExportCDAAsPDFLoading
}) => {
  const history = useHistory();
  const [isAttachment, setIsAttachment] = useState(false);

  useEffect(() => {
    const params = queryString.parse(history.location.search);

    if (params.attachment) {
      setIsAttachment(true);
    } else {
      setIsAttachment(false);
    }
  }, [history.location.search]);

  return (
    <>
      {isAttachment ? (
        <AttachmentViewer getPatientAttachment={getPatientAttachment} />
      ) : (
        <CDAMainViewer
          ehrRecord={ehrRecord}
          requestPatientRecord={requestPatientRecord}
          isNewRecordAvailable={isNewRecordAvailable}
          exportCDAAsPDF={exportCDAAsPDF}
          exportCDAAsPDFLoading={exportCDAAsPDFLoading}
          setExportCDAAsPDFLoading={setExportCDAAsPDFLoading}
        />
      )}
    </>
  );
};

export default Main;

import dayjs from 'dayjs';
import { format } from 'date-fns';

const createDocumentHeader = ($) => {
  if (!$) {
    return;
  }

  let contents = [];
  let documentTitle = 'Clinical Document';

  // Document Title
  const docTitle = $('title').first().text();
  if (docTitle) {
    documentTitle = docTitle;
  }

  // RECORD TARGET
  let patientFirstName = $('patient > name > given').first().text();
  let patientLastName = $('patient > name > family').first().text();
  let patientName = `${patientFirstName} ${patientLastName}`;
  if (patientName) {
    patientName = patientName.trim();
    contents.push({ key: 'Patient', value: patientName });
  }

  let dob = $('patient > birthTime').attr('value');
  if (dob) {
    contents.push({
      key: 'Date of Birth',
      value: dayjs(dob).format('MM-DD-YYYY')
    });
  }

  let sex = $('patient > administrativeGenderCode').attr('code');
  if (sex) {
    sex = sex === 'M' ? 'Male' : sex === 'F' ? 'Female' : null;
    contents.push({ key: 'Sex', value: sex });
  }

  let patientId = $('id').first().attr('root');
  if (patientId) {
    contents.push({ key: 'Patient ID', value: patientId });
  }

  // HEADER ELEMENTS
  let documentId = $('id').first().attr('root');
  if (documentId) {
    contents.push({ key: 'Document ID', value: documentId });
  }

  let documentCreated = $('effectiveTime').attr('value');
  const documentCreatedTime = documentCreated
    ? documentCreated.split('-')[0].split('+')[0]
    : '';
  if (documentCreated) {
    contents.push({
      key: 'Created At',
      value: `${dayjs(documentCreatedTime).format('MM-DD-YYYY hh:mmA')}`
    });
  }

  // // AUTHOR
  // let author = $('assignedAuthor').first().text();
  // if (author) {
  //   author = author.trim();
  //   contents.push({ key: 'Author', value: author });
  // }

  // let representedOrganization = $('representedOrganization > name')
  //   .first()
  //   .text();
  // if (representedOrganization) {
  //   representedOrganization = representedOrganization.trim();
  //   contents.push({
  //     key: 'Represented Organization',
  //     value: representedOrganization
  //   });
  // }

  let representedOrganizationContactInfo = $('addr, telecom')
    .first()
    .children()
    .toArray()
    .map((item) => {
      return $(item).text();
    })
    .join(' ');
  if (representedOrganizationContactInfo) {
    contents.push({
      key: 'Contact Info',
      value: representedOrganizationContactInfo
    });
  }

  // Authenticator
  let assignedPerson = $('assignedEntity > assignedPerson > name')
    .first()
    .text();
  if (assignedPerson) {
    assignedPerson = assignedPerson;

    contents.push({ key: 'Assigned Person', value: assignedPerson });
  }

  let assignedPersonContact = $(
    'assignedEntity > addr, assignedEntity > telecom'
  )
    .first()
    .text();
  if (assignedPersonContact) {
    assignedPersonContact = assignedPersonContact;

    contents.push({
      key: 'Assigned Person Contact',
      value: assignedPersonContact
    });
  }

  // Legal Authentiator
  let legalAuthenticator = $('legalAuthenticator').text();
  let legalAuthenticatorAt = $('legalAuthenticator > time').attr('value');
  if (legalAuthenticator) {
    legalAuthenticator = legalAuthenticator;

    if (legalAuthenticatorAt) {
      legalAuthenticatorAt = dayjs(legalAuthenticatorAt.split('+')[0]).format(
        'YYYY-MM-DD'
      );

      legalAuthenticator = `${legalAuthenticator} signed at ${legalAuthenticatorAt}`;
    }

    contents.push({ key: 'Legal Authenticator', value: legalAuthenticator });
  }

  return { documentTitle, contents };
};

export default createDocumentHeader;

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);

    /*
    if (!isShowing) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    */
  }

  return {
    isShowing,
    toggle
  };
};

const Modal = ({ isShowing, hide, children }) => {
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span>
                    <X />
                  </span>
                </button>
              </div>
              <div className="modal-container">{children}</div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export { useModal, Modal };

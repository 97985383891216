import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  box-shadow: none;

  border-color: #7367f0;
  background-color: #7367f0;
  color: #ffffff;
  border: 1px solid #7367f0;
  padding: 0.7rem 1rem;
  border-radius: 0.358rem;
  text-align: ${({ align }) => (align ? align : 'center')};
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  user-select: none;
  outline: none;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  transition: all 0.5s ease;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover:not(.outline, .grey) {
    box-shadow: 0 8px 25px -8px #7367f0;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }

  &.outline {
    background-color: transparent;
    color: #7367f0;
  }

  &.grey {
    background-color: transparent;
    border: 1px solid #82868b;
    color: #82868b;

    &:hover {
      background-color: rgba(130, 134, 139, 0.04);
    }
  }

  &.flex {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  &.green {
    border: 1px solid #28c76f;
    color: #28c76f;

    &:hover {
      background-color: rgba(40, 199, 111, 0.08);
    }
  }

  &.lightblue {
    border: 1px solid #00cfe8;
    color: #00cfe8;

    &:hover {
      background-color: rgba(0, 207, 232, 0.08);
    }
  }

  &.small {
    font-size: 0.85rem;
    padding: 0.486rem 1rem;

    svg {
      width: 0.85rem;
      height: 0.85rem;
      margin-left: 0.5rem;
    }
  }

  &.sub {
    border: none;
    margin: 15px 10px;
    padding: 0;
  }
`;

// import * as cheerio from 'cheerio';
// import cheerioTableparser from 'cheerio-tableparser';
// import Tabletojson from './table2json';
import createTable from './createTable';

const createElement = ($, el) => {
  if ($(el)[0].name === 'table') {
    // const { columns, data } = createTable($, $(el));

    // const tableHtmlString = `<table>${$(el).html()}</table>`;
    // const Table2Json = new Tabletojson();
    // const convertedRows = Table2Json.convert(tableHtmlString)[0];
    // const data = convertedRows ? convertedRows.map((row) => row) : [];
    // const columns = data[0] ? Object.keys(data[0]).map((key) => key) : [];

    let columns, data;

    const isHccTable = $(el).attr('class') === 'hcc';

    if (isHccTable) {
      const { columns: _columns, data: _data } = createTable($, $(el));
      columns = _columns;
      data = _data;
    } else {
      const parsedTableData = $(el).parsetable(false, false, true);

      columns = parsedTableData.map((el) => el[0]);

      data = [];

      const tableDataWithoutColumnName = parsedTableData.map((arr) =>
        arr.slice(1)
      );

      let idx = 0;
      let max = tableDataWithoutColumnName[0].length;

      while (idx < max) {
        let row = {};
        columns.forEach((col) => {
          let colIdx = columns.indexOf(col);
          row[col] = tableDataWithoutColumnName[colIdx][idx];
        });

        data.push(row);

        idx++;
      }
    }

    return {
      type: $(el)[0].name,
      content: {
        columns: columns,
        data: data
      }
    };
  } else if (
    $(el)[0].name === 'paragraph' ||
    $(el)[0].name === 'caption' ||
    $(el)[0].name === 'footnote'
  ) {
    const captionText = $(el).text();
    return {
      type: $(el)[0].name,
      content: captionText
    };
  }
};

const createDocumentBody = ($) => {
  if (!$) {
    return;
  }

  let documentBody = [];

  const sections = $('section').toArray();

  sections.forEach((section) => {
    let finalSection = {
      title: 'Section',
      data: []
    };

    // Section Title
    const sectionTitle = $(section).find('title').text();
    finalSection['title'] = sectionTitle;

    // Actual Content inside <text> tag
    const sectionText = $(section).find('text');
    const sectionContents = $(sectionText)
      .find('footnote, caption, table, > paragraph')
      .toArray();

    if (sectionContents.length < 1) {
      const rawSectionText = $(sectionText).text();
      finalSection['data'].push({
        type: 'raw',
        content: rawSectionText
      });
    } else {
      sectionContents.forEach((el) => {
        const element = createElement($, el);
        finalSection['data'].push(element);
      });
    }

    documentBody.push(finalSection);
  });

  return documentBody;
};

export default createDocumentBody;

import styled from 'styled-components';

export const RTFWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 85%;
  overflow: hidden;
  top: 100px;
  left: 100px;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem;
`;

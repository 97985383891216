import cheerio from 'cheerio';

export const exportSection = (rawDocument, sectionTitle) => {
  if (!rawDocument || !sectionTitle) return;

  const $ = cheerio.load(rawDocument, {
    xml: { xmlMode: true }
  });

  const $ClinicalDocument = $('ClinicalDocument');
  const $structuredBody = $('structuredBody');

  $structuredBody.find('component').each((i, component) => {
    const title = $(component).find('title').text();

    if (sectionTitle !== title) {
      $(component).remove();
    }
  });

  $ClinicalDocument.find('component').each((i, component) => {
    const nonXMLBody = $(component).find('nonXMLBody');

    if (nonXMLBody.length > 0) {
      $(component).remove();
    }
  });

  const exportedSection = $.xml();

  return exportedSection;
};

export const exportRows = (rawDocument, rowsToExport) => {
  if (!rawDocument || !rowsToExport) return;

  console.log('[exportRows] rowsToExport: ', rowsToExport);

  const $ = cheerio.load(rawDocument, {
    xml: { xmlMode: true }
  });

  const $ClinicalDocument = $('ClinicalDocument');
  const $structuredBody = $('structuredBody');

  let linkedAttachmentIds = [];

  $structuredBody.find('component').each((i, component) => {
    const title = $(component).find('title').text();

    const section = rowsToExport.find((el) => el.sectionTitle === title);

    if (section) {
      const tableBody = $(component).find('tbody');

      $(tableBody)
        .find('> tr')
        .each((i, row) => {
          const rowText = $(row).children().text();
          let hasSelectedRow = false;

          section.rows.forEach((selectedRow) => {
            const stringArr = Object.keys(selectedRow).map((col) => {
              if (
                typeof selectedRow[col] === 'object' &&
                selectedRow[col].length
              ) {
                // let mergedStringArr = [];
                selectedRow[col].map((el) => {
                  // // return underlying evidence rows as mergesstring
                  // mergedStringArr.push('Context');
                  // mergedStringArr.push('Location');
                  // mergedStringArr.push(el.context);
                  // mergedStringArr.push(el.location);

                  // add linked attachments to array
                  const attachmentId = el.ref.split('+')[1];
                  const hasAttachment =
                    linkedAttachmentIds.includes(attachmentId);
                  if (!hasAttachment) {
                    linkedAttachmentIds.push(attachmentId);
                  }
                });
                // return mergedStringArr.join('');
              } else {
                return selectedRow[col];
              }
            });

            const joinedString = stringArr.join('');

            const trimmedRowText = rowText.replace(/\s+/g, '');
            const trimmedjoinedString = joinedString.replace(/\s+/g, '');

            if (trimmedRowText.startsWith(trimmedjoinedString)) {
              hasSelectedRow = true;
            }
          });

          if (!hasSelectedRow) {
            const referenceId = $(row).find('[ID]').attr('ID');

            $(component)
              .find('entry')
              .each((i, etry) => {
                const refValue = $(etry).find('reference[value]').attr('value');
                if (refValue && refValue.includes(referenceId)) {
                  $(etry).remove();
                }
              });

            const $row = $(row);

            $row.remove();
          }
        });
    } else {
      $(component).remove();
    }
  });

  $ClinicalDocument.find('component').each((i, component) => {
    const nonXMLBody = $(component).find('nonXMLBody');

    $(nonXMLBody)
      .find('text')
      .each((i, attachment) => {
        const attachmentId = $(attachment).attr('ID');

        if (!linkedAttachmentIds.includes(attachmentId)) {
          $(attachment).remove();
        }
      });
  });

  const exportedRows = $.xml();

  return exportedRows;
};

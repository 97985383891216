import cheerio from 'cheerio';
import cheerioTableparser from 'cheerio-tableparser';
import axios from 'axios';

import pullAttachments from './pullAttachments';
import createDocumentHeader from './createDocumentHeader';
import createDocumentBody from './createDocumentBody';
import createSearchData from './createSearchData';

const parseDocument = async (document) => {
  if (!document) {
    return;
  }

  let doc;

  if (document.startsWith('/static')) {
    try {
      const res = await axios.get(document, {
        'Content-Type': 'application/xml'
      });

      doc = res.data;
    } catch (error) {
      console.dir(error);
      return;
    }
  } else {
    doc = document;
  }

  const $ = cheerio.load(doc, {
    xml: { xmlMode: true }
  });

  // Cheerio Table parser extension
  cheerioTableparser($);

  // Document Header
  const { documentTitle, contents: header } = createDocumentHeader($);

  // Document Body
  const body = createDocumentBody($);

  // Attachments
  const attachments = pullAttachments($);

  const searchData = createSearchData(body, attachments);

  const parsedDocument = {
    // documentTitle,
    header,
    body,
    attachments,
    searchData
  };

  console.log('[parseDocument] parsedDocument', parsedDocument);

  return parsedDocument;
};

export default parseDocument;

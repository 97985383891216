import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ChevronDown, ExternalLink } from 'react-feather';
import { parse } from 'query-string';
import { Element } from 'react-scroll';

import { TableWrapper, SubRowWrapper } from './Table.style';

export const Table = ({
  columns,
  data,
  sectionTitle,
  setRowsToExport,
  resetRows
}) => {
  const history = useHistory();

  const [searchValues, setSearchValues] = useState(null);

  const dataColumns = columns.map((col) => ({
    name: col,
    selector: col,
    sortable: false,
    wrap: true,
    cell: (row, idx) => {
      const rowId = `${sectionTitle}-${idx}`;
      return (
        <Element name={rowId} style={{ whiteSpace: 'pre-line' }}>
          {row[col]}
        </Element>
      );
    }
  }));

  const searchParams = parse(history.location.search);

  const normalize = (str) => {
    if (typeof str !== 'string') return '';
    return decodeURIComponent(encodeURIComponent(str))
      .toLowerCase()
      .replace(/\s/g, '');
  };

  const conditionalRowStyles = [
    {
      when: (row) => {
        if (searchParams.q) {
          const rowStr = Object.values(row).join('');
          const normalizedRowStr = normalize(rowStr);
          const normalizedSearchStr = normalize(searchParams.q);

          return normalizedRowStr.includes(normalizedSearchStr);
        } else if (searchParams.rowId) {
          const search = searchParams.rowId.split('-');
          const searchSection = search[0];
          const searchIdx = search[1];
          return (
            searchSection === sectionTitle &&
            data[searchIdx].Category === row.Category
          );
        } else {
          return false;
        }
      },
      style: {
        backgroundColor: '#7367f0',
        color: 'white'
      }
    }
  ];

  const EvidencePDF = ({ data }) => {
    const fileTitle = data.row.location;
    const fileId = data.locationRef.id;
    const fileType = data.locationRef.type;

    const encounterDate =
      data.details && data.details['encounterDate:']
        ? data.details['encounterDate:'].value
        : '';

    let icd10Code, searchString;
    if (data.details) {
      // Search for ICD 10 Code
      Object.keys(data.details).some((key) => {
        if (
          key.toLowerCase().includes('icd-10') ||
          key.toLowerCase().includes('icd10')
        ) {
          icd10Code = data.details[key].value;
          return true;
        }
      });

      // Search for search string (xpath)
      Object.keys(data.details).some((key) => {
        if (data.details[key].searchString) {
          searchString = data.details[key].searchString;
          return true;
        }
      });
    }

    return (
      <div className="evidence-wrapper">
        <div className="source-details">
          {icd10Code ? (
            <div className="icdCode">
              <h4>{icd10Code}</h4>
            </div>
          ) : null}
          {encounterDate ? (
            <div className="encounterDate">
              <h5>
                <span>Encounter date:</span> {encounterDate}
              </h5>
            </div>
          ) : null}
          <div className="context">
            <h5>
              {data.locationRef.page
                ? `PDF - Page ${data.locationRef.page}:`
                : 'Context'}
            </h5>
            <p>{decodeURIComponent(data.row.context)}</p>
          </div>
          <div className="source-link">
            {data.locationRef.id ? (
              <>
                Raw evidence:{' '}
                <Link
                  to={`${
                    history.location.pathname
                  }?attachment=${fileId}&type=${fileType}${
                    data.locationRef.page
                      ? `&page=${data.locationRef.page}`
                      : data.locationRef.query
                      ? `&${data.locationRef.query}`
                      : ''
                  }`}
                >
                  <span>
                    {data.row.location.slice(
                      0,
                      data.row.location.indexOf(' - Page')
                    )}
                  </span>
                  <ExternalLink className="external-link-icon" />
                </Link>
              </>
            ) : (
              <span>{data.row.location}</span>
            )}
          </div>
        </div>
        {/* <div className="evidence-row">
          <div className="context">
            <h5>Context</h5>
            <p>{data.row.context}</p>
          </div>
          <div className="location">
            <h5>Location</h5>
            {data.locationRef.id ? (
              <Link
                to={`${
                  history.location.pathname
                }?attachment=${fileId}&type=${fileType}${
                  data.locationRef.page
                    ? `&page=${data.locationRef.page}`
                    : data.locationRef.query
                    ? `&${data.locationRef.query}`
                    : ''
                }&title=${fileTitle}`}
              >
                {data.row.location}
              </Link>
            ) : (
              <span>{data.row.location}</span>
            )}
          </div>
        </div> */}
        <div className="evidence-details">
          <h6>Additional details</h6>
          <>
            {Object.keys(data.details).map((key) => (
              <div className="evidence-detail-row">
                <div className="evidence-detail-key">{key}</div>
                <div className="evidence-detail-value">
                  {data.details[key].searchString ? (
                    <Link
                      to={`${history.location.pathname}?${
                        fileId ? `attachment=${fileId}&type=${fileType}` : ''
                      }${
                        data.details[key].searchString
                          ? `&search=${data.details[key].searchString}`
                          : ''
                      }&title=${fileTitle}`}
                    >
                      {data.details[key].value}
                    </Link>
                  ) : (
                    data.details[key].value
                  )}
                </div>
              </div>
            ))}
          </>
        </div>
        {/* <div className="source-file">
          <span>
            Source file:{' '}
            {data.locationRef.id ? (
              <Link
                to={`${history.location.pathname}?attachment=${fileId}&type=${fileType}`}
              >
                {data.row.location.slice(
                  0,
                  data.row.location.indexOf(' - Page')
                )}
                <ExternalLink className="external-link-icon" />
              </Link>
            ) : (
              <span>
                {data.row.location.slice(
                  0,
                  data.row.location.indexOf(' - Page')
                )}
              </span>
            )}
          </span>
        </div> */}
      </div>
    );
  };

  const EvidenceXML = ({ data }) => {
    const fileTitle = data.row.location;
    const fileId = data.locationRef.id;
    const fileType = data.locationRef.type;

    const encounterDate =
      data.details && data.details['encounterDate:']
        ? data.details['encounterDate:'].value
        : '';

    let icd10Code, searchString;
    if (data.details) {
      // Search for ICD 10 Code
      Object.keys(data.details).some((key) => {
        if (
          key.toLowerCase().includes('icd-10') ||
          key.toLowerCase().includes('icd10')
        ) {
          icd10Code = data.details[key].value;
          return true;
        }
      });

      // Search for search string (xpath)
      Object.keys(data.details).some((key) => {
        if (data.details[key].searchString) {
          searchString = data.details[key].searchString;
          return true;
        }
      });
    }

    return (
      <div className="evidence-wrapper">
        <div className="source-details">
          {icd10Code ? (
            <div className="icdCode">
              <h4>{icd10Code}</h4>
            </div>
          ) : null}
          {encounterDate ? (
            <div className="encounterDate">
              <h5>
                <span>Encounter date:</span> {encounterDate}
              </h5>
            </div>
          ) : null}
          <div className="source-link">
            {data.locationRef.id ? (
              <Link
                to={`${
                  history.location.pathname
                }?attachment=${fileId}&type=${fileType}${
                  searchString ? `&search=${searchString}` : ''
                }&title=${fileTitle}`}
              >
                View raw evidence
                <ExternalLink className="external-link-icon" />
              </Link>
            ) : (
              <span>{data.row.location}</span>
            )}
          </div>
        </div>
        {data.details ? (
          <div className="evidence-details">
            <h6 style={{ marginBottom: 10 }}>Additional details</h6>
            {Object.keys(data.details).map((key) => (
              <div className="evidence-detail-row">
                <div className="evidence-detail-key">{key}</div>
                <div className="evidence-detail-value">
                  {data.details[key].value}
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="source-file">
          <span>
            Source file:{' '}
            {data.locationRef.id ? (
              <Link
                to={`${history.location.pathname}?attachment=${fileId}&type=${fileType}&title=${fileTitle}`}
              >
                {data.row.location}
                <ExternalLink className="external-link-icon" />
              </Link>
            ) : (
              <span>{data.row.location}</span>
            )}
          </span>
        </div>
      </div>
    );
  };

  const NestedContent = ({ data }) => {
    return (
      <SubRowWrapper>
        {data.nestedContent.map((row, i) => {
          const fileReference = row.ref.split('+');
          const fileType = fileReference[0];

          let fileId = fileReference[1];

          const fileQuery = fileReference[2];

          let locationRef;

          if (fileType === 'pdf') {
            locationRef = {
              type: fileType,
              id: fileId,
              page: fileQuery
            };
          } else if (fileType === 'xml') {
            locationRef = {
              type: fileType,
              id: fileId,
              query: fileQuery
            };
          }

          const evidenceData = {
            row: row,
            locationRef: locationRef,
            details: row.details
          };

          return fileType === 'pdf' ? (
            <EvidencePDF data={evidenceData} />
          ) : (
            <EvidenceXML data={evidenceData} />
          );
        })}
      </SubRowWrapper>
    );
  };

  return (
    <TableWrapper>
      <DataTable
        columns={dataColumns}
        data={data}
        sortIcon={<ChevronDown size={10} />}
        expandableRows={true}
        expandableRowsComponent={<NestedContent />}
        expandOnRowClicked={true}
        noHeader
        responsive
        conditionalRowStyles={conditionalRowStyles}
        selectableRows
        selectableRowsHighlight
        clearSelectedRows={resetRows}
        onSelectedRowsChange={(e) =>
          setRowsToExport((prev) => {
            if (!e.selectedRows.length) {
              return prev.filter((el) => el.sectionTitle !== sectionTitle);
            }

            let newArr = [];

            const newRowsData = {
              sectionTitle: sectionTitle,
              rows: e.selectedRows
            };

            const hasSection = prev.find(
              (el) => el.sectionTitle === newRowsData.sectionTitle
            );

            if (prev.length && !hasSection) {
              newArr.push(...prev);
              newArr.push(newRowsData);

              return newArr;
            } else if (!prev.length && !hasSection) {
              newArr.push(newRowsData);

              return newArr;
            }

            prev.map((row) => {
              if (row.sectionTitle === newRowsData.sectionTitle) {
                newArr.push({
                  sectionTitle: row.sectionTitle,
                  rows: newRowsData.rows
                });
              } else {
                newArr.push(row);
              }
            });

            return newArr;
          })
        }
      />
    </TableWrapper>
  );
};

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const StyledOverlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(34, 41, 47, 0.5);
`;

export const Overlay = ({ show }) => {
  return show
    ? ReactDOM.createPortal(
        <Fragment>
          <StyledOverlay />
        </Fragment>,
        document.body
      )
    : null;
};

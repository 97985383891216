import React from 'react';

import { ButtonWrapper } from './Button.style';

export const Button = ({ text, icon, onClick, ...props }) => {
  return (
    <ButtonWrapper onClick={onClick} {...props}>
      {text}
      {icon ? icon : null}
    </ButtonWrapper>
  );
};

import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  transition: 300ms ease all;
  margin-left: ${({ expanded }) => (expanded ? '260px' : '80px')};
  padding: 6rem 1rem 0;

  @media (max-width: 1199px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    padding: 6rem 0.5rem 0;
  }
`;

export const NavbarWrapper = styled.nav`
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin: 1rem 1rem 0;
  border-radius: 0.428rem;
  position: fixed;
  right: 0;
  background: #ffffff;
  padding: 0;
  transition: 300ms ease all;
  min-height: 4rem;
  z-index: 100;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: ${({ expanded }) =>
    expanded
      ? 'calc( 100vw - (100vw - 100%) - calc(1rem * 2) - 260px)'
      : 'calc(100vw - (100vw - 100%) - 2.4rem - 74px)'};

  @media (max-width: 1199px) {
    width: calc(100% - 2rem);
  }

  @media (max-width: 768px) {
    width: calc(100% - 1rem);
    margin: 1rem 0.5rem 0;
  }
`;

export const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  flex-basis: 100%;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }

  .navbar-left {
  }

  .navbar-right {
    svg {
      margin-right: 1.2rem;
    }
  }

  .navbar-center {
    position: relative;
    cursor: text;
    width: 100%;
    height: 100%;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    span {
      color: #6e6b7b;
      line-height: 16px;
      font-size: 1rem;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const SidebarWrapper = styled.div`
  transition: 300ms ease all;
  position: fixed;
  left: 0;
  background: #fff;
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  z-index: 100;
  height: 100%;

  width: ${({ expanded }) => (expanded ? '260px' : '80px')};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .sidebar-header {
    padding: .8rem 1rem 1rem 1.64rem;
    position: relative;
    overflow: scroll;
    margin-top: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    cursor: pointer;

    img {
      user-select: none;
      height: 23px;
    }

    button svg {
      display: flex;
    }

    button {
      min-width: 120px;
    }

    svg {
      display:none;
    }

    @media (max-width: 1199px) {
     svg {
       display: flex;
     }
    }
  }

  .sidebar-body {
    overflow-hidden;
    height: calc(100% - 12rem);
    
    &.full {
      height: calc(100% - 4rem) !important;
    }

    h5 {
      color: #a6a4b0;
      margin: calc(1.5rem + 0.286rem) 0 0.8rem 2.1rem;
      min-width: 150px;
      overflow: hidden;
    }

    ul {
      padding-bottom: 0.75rem;
      position: relative;
      overflow-x: hidden;
      overflow-y: ${({ expanded, menuHover }) =>
        expanded || menuHover ? 'auto' : 'hidden'};
      height: calc(100% - 4.45rem) !important;
    }

    li {
      transition: all 300ms ease;
      display: flex;
      align-items: center;
      list-style: none;
      position: relative;

      &:hover {
        transform: translateX(5px);
      }
    }
  }

  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin: 0 15px;
    padding: 10px 15px 10px 15px;
    color: #625f6e;
    line-height: 1.45;
    display: flex;
    align-items: center !important;
    width: 100%;
    min-height: 43px;
  



    .nav-text {
      display: ${({ expanded, menuHover }) =>
        expanded || menuHover ? 'flex' : 'none'};
        margin-left: .5rem;
        min-width: 160px;
    }

    &.active {
      background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
      color: #fff;
      border-radius: 4px;
    }
  }

  &:hover {
    width: 260px;
  }

  .nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }

  @media (max-width: 1199px) {
    width: 260px;
    left: -260px;

    a .nav-text {
      display: flex;
    }

    &.open {
      transform: translate3d(260px,0,0);
    }
  }

  .badge {
    margin-top: .5rem;
    padding: .2rem .5rem 0 2rem;
    background-color: rgba(255,159,67,.12);
    color: #ff9f43!important;
    padding: .3rem .5rem;
    text-align: center;
    border-radius: .358rem;
    font-size: 85%;
    font-weight: 600;
    max-width: 200px;
    overflow: hidden;
    word-break: break-all;

    &.green {
      color: #28c76f !important;
      background-color: rgba(40, 199, 111, 0.08);
    }
  }

  .record-data {
    padding: .2rem .5rem 0 2rem;

    .update-status {
      display: flex;
      align-items: center;
    }

    .refresh-btn {
      margin-left: .5rem;
      background-color: #ff9f43;
      border-color: #ff9f43;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      padding: .4rem .414rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        box-shadow: 0 8px 25px -8px #ff9f43;
      }
    }

    svg.collapsed {
      margin: 0.6rem 0 0 0.3rem;
      width: 1rem;
      height: 1rem;
      color: #6e6b7b;
    }

     .flex {
      min-width: 120px;
    }

    .new-version-status {
      margin-top: 1rem;

      .badge {
        color: #28c76f !important;
        background-color: rgba(40, 199, 111, 0.08);
      }
    }

    h5 {
      margin-left: .1rem;
      color: #ff9f43!important;
    }

    button {
      margin-top: 1rem;
    }
  }

  .export-buttons {
    padding: 1rem .5rem 0 2rem;

    h5 {
      margin: 2.1rem;  
      color: #a6a4b0;
    }
  }

  .reset-button {
    margin-top: 1rem;
  }
`;

export const NavbarSearchWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;

  .search-input-icon {
    position: absolute;
    left: 1.5rem;
    top: 35%;
  }

  .search-input-close {
    position: absolute;
    right: 2rem;
    top: 35%;

    svg {
      cursor: pointer;
    }
  }

  svg {
    height: 1.3rem;
    width: 1.3rem;
    font-size: 1.3rem;
    color: #6e6b7b;
  }
`;
export const FooterWrapper = styled.div`
  ul {
    float: right;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 1rem;
  }

  ul li {
    margin: 0 1rem;
  }
`;

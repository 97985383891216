import React, { useState } from 'react';

export const DocumentContext = React.createContext();

const DocumentProvider = ({ children }) => {
  const [currentDocument, setCurrentDocument] = useState(null);
  const [rawDocument, setRawDocument] = useState(null);
  const [recordData, setRecordData] = useState(null);

  if (currentDocument instanceof Error) {
    return (
      <>
        <p>Error while parsing the Document</p>
        <pre>{document.stack || document.message}</pre>
      </>
    );
  }

  return (
    <DocumentContext.Provider
      value={{
        currentDocument,
        setCurrentDocument,
        rawDocument,
        setRawDocument,
        recordData,
        setRecordData
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export default DocumentProvider;

import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    letter-spacing: .01rem;
}

body {
    background-color: #F8F8F8;
    font-family: 'Lato', sans-serif;
}

body.modal-open {
  overflow: hidden;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Railway', sans-serif;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873;
}

table {
    width: 100%;
    height: 100%;
    color: #6e6b7b;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  padding: .5rem;
}

.modal {
  z-index: 9999;
  background: white;
  position: relative;
  overflow:hidden;
  margin: auto;
  border-radius: 0.428rem;
  max-width: 800px;
  padding: 1rem;
  height: 100%;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modal-container {
  height: 100%;
}

.section-title {
  padding: 1.5rem .5rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 1px solid #ebe9f1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  row-gap: 1rem;

  justify-items: left;

  @media(min-width: 432px) {
    button {
      margin-left: auto;
    }
  }
}
`;

export const Section = styled.div`
  margin-bottom: 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
  width: 100%;
  overflow: auto;
`;

export default GlobalStyle;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Spinner } from './components';

import App from './App';

// import { mockEhrRecord } from './data/mockData';

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Icon } from '../../components';
import { ListItemWrapper } from './AttachedDocs.styled';

const ListItem = ({ item }) => {
  const history = useHistory();

  return (
    <Link
      to={`${history.location.pathname}?attachment=${item.id}&type=${
        item.mediaType.split('/')[1]
      }&title=${item.title}`}
    >
      <ListItemWrapper>
        <Icon title={item.mediaType} />
        <span>{item.title}</span>
      </ListItemWrapper>
    </Link>
  );
};

export default ListItem;

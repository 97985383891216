import React from 'react';

import ListItem from './ListItem';
import { AttachedDocsWrapper } from './AttachedDocs.styled';

export const AttachedDocs = ({ attachmentData }) => {
  return (
    <AttachedDocsWrapper>
      <h3>Attached Documents</h3>
      <div className="attachment-list">
        {attachmentData &&
          attachmentData.map((item, i) => {
            return <ListItem item={item} />;
          })}
      </div>
    </AttachedDocsWrapper>
  );
};

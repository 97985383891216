import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns
} from 'react-table';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Element } from 'react-scroll';

import { TableWrapper } from './TableV2.style';

export const TableV2 = ({
  columns,
  data,
  sectionTitle,
  setRowsToExport,
  resetRows
}) => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const isMarked = (id) => {
    const rowId = params.rowId;
    if (id === rowId) {
      console.log('IS MARKED!!!');
    }

    return id === rowId;
  };

  const _columns = useMemo(() => {
    return columns.map((col) => ({
      Header: col,
      accessor: col
    }));
  }, []);
  const _data = useMemo(() => data, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected
  } = useTable(
    { columns: _columns, data: _data, autoResetSelectedRows: true },
    useSortBy,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          disableResizing: true,
          minWidth: 31,
          width: 31,
          maxWidth: 31,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  useEffect(() => {
    const selectedRows =
      selectedFlatRows && selectedFlatRows.length && !resetRows
        ? selectedFlatRows.map((row) => row.original)
        : [];

    setRowsToExport((prev) => {
      if (!selectedRows.length) {
        return prev.filter((el) => el.sectionTitle !== sectionTitle);
      }

      let newArr = [];

      const newRowsData = {
        sectionTitle: sectionTitle,
        rows: selectedRows
      };

      const hasSection = prev.find(
        (el) => el.sectionTitle === newRowsData.sectionTitle
      );

      if (prev.length && !hasSection) {
        newArr.push(...prev);
        newArr.push(newRowsData);

        return newArr;
      } else if (!prev.length && !hasSection) {
        newArr.push(newRowsData);

        return newArr;
      }

      prev.map((row) => {
        if (row.sectionTitle === newRowsData.sectionTitle) {
          newArr.push({
            sectionTitle: row.sectionTitle,
            rows: newRowsData.rows
          });
        } else {
          newArr.push(row);
        }
      });

      return newArr;
    });

    if (resetRows) {
      console.log('resetRows::: ', resetRows);
      toggleAllRowsSelected(false);
    }
  }, [selectedFlatRows, resetRows]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const removeRowId = (id) => {
    const isMarkedRow = isMarked(id);
    if (isMarkedRow) {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete('rowId');
      history.replace({
        search: queryParams.toString()
      });
    }
  };

  return (
    <>
      <TableWrapper {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div>
                    <div
                      className="header-content"
                      {...column.getSortByToggleProps()}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <>
                              {' '}
                              <ChevronDown size={12} />
                            </>
                          ) : (
                            <>
                              {' '}
                              <ChevronUp size={12} />
                            </>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    {column.canResize && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowId = `${sectionTitle}-${row.id}`;
            return (
              <Element name={rowId}>
                <tr
                  {...row.getRowProps()}
                  className={`${isMarked(rowId) ? 'isMarked' : ''} ${
                    row.isSelected ? 'selected' : ''
                  }`}
                  onClick={() => removeRowId(rowId)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Element>
            );
          })}
        </tbody>
      </TableWrapper>
    </>
  );
};

import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ReactDOM from 'react-dom';
import { Link } from 'react-scroll';
import Highlighter from 'react-highlight-words';

import { Overlay, Icon } from '../../components';
import { DocumentContext } from '../../context/DocumentProvider';
import useOnClickOutside from '../../lib/utils/hooks/useOnClickOutside';
import { AutoCompleteWrapper, ListWrapper } from './AutoComplete.style';

const SuggestionList = ({
  renderSuggestions,
  setShowSuggestions,
  setNavbarSearch
}) => {
  const searchContainer = useRef(null);

  useOnClickOutside(searchContainer, () => {
    setShowSuggestions(false);
    setNavbarSearch(false);
  });

  return <ListWrapper ref={searchContainer}>{renderSuggestions()}</ListWrapper>;
};

export const AutoComplete = ({ setNavbarSearch, searchData }) => {
  const [userInput, setUserInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const history = useHistory();

  const { currentDocument } = useContext(DocumentContext);

  const setSearchParams = (key, value) => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.delete('section');
    queryParams.delete('rowId');
    queryParams.set(key, value);

    history.replace({
      pathname: history.location.pathname,
      search: queryParams.toString()
    });
  };

  useOnClickOutside(inputRef, () => {
    if (!showSuggestions) {
      setNavbarSearch(false);
    }
  });

  const openAttachment = (fileName) => {
    console.log('[openAttachment] fileName: ', fileName);
    const attachmentFile = currentDocument.attachments.find(
      (att) => att.title === fileName
    );

    const id = attachmentFile.id;
    const type = attachmentFile.mediaType === 'text/xml' ? 'xml' : 'pdf';
    const title = attachmentFile.title;

    history.push({
      pathname: history.location.pathname,
      search: `?attachment=${id}&type=${type}&title=${title}`
    });
  };

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '');
  };

  const renderSuggestions = () => {
    const searchLimit = 20;
    let filteredData = {};

    if (!searchData) return;

    searchData.forEach((suggestion) => {
      filteredData[suggestion.groupTitle] = [];

      const sortData = suggestion.data
        .filter((i) => {
          if (i && i.text) {
            const normalizedData = normalizeString(i.text);
            const normalizedInput = normalizeString(userInput);

            const startCondition = normalizedData.startsWith(normalizedInput);
            const includeCondition = normalizedData.includes(normalizedInput);

            if (startCondition) {
              return startCondition;
            } else if (!startCondition && includeCondition) {
              return includeCondition;
            } else {
              return null;
            }
          }

          return null;
        })
        .slice(0, searchLimit);

      filteredData[suggestion.groupTitle].push(...sortData);
    });

    return Object.keys(filteredData).map((category, i) => {
      return (
        <>
          {filteredData[category].length ? (
            <Fragment key={i}>
              <div className="suggestion-item suggestion-category">
                <Icon title={category} />
                <span className="suggestion-header-category">{category}</span>
              </div>
              <div className="suggestion-list">
                {filteredData[category].map((suggestion, i) => (
                  <Link
                    key={i}
                    to={
                      category === 'Sections'
                        ? suggestion
                        : category === 'Attachments'
                        ? ''
                        : category
                    }
                    offset={-250}
                    smooth={true}
                    spy={true}
                    onClick={() => {
                      if (category === 'Attachments') {
                        openAttachment(suggestion.text);
                      } else {
                        const key = suggestion.rowId ? 'rowId' : 'section';
                        setSearchParams(key, suggestion[key]);
                      }
                      setNavbarSearch(false);
                    }}
                  >
                    {/* <li className="suggestion-item">{suggestion}</li> */}
                    <li className="suggestion-item">
                      <Highlighter
                        highlightClassName="highlighted"
                        searchWords={[userInput]}
                        autoEscape={true}
                        textToHighlight={suggestion.text}
                      />
                    </li>
                  </Link>
                ))}
              </div>
            </Fragment>
          ) : null}
        </>
      );
    });
  };

  const onChange = (e) => {
    setUserInput(e.target.value);
    setShowSuggestions(true);

    if (e.target.value < 1) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    const textInput = ReactDOM.findDOMNode(inputRef.current);

    if (textInput !== null) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <AutoCompleteWrapper>
        <input
          type="text"
          placeholder="Search..."
          value={userInput}
          onChange={(e) => {
            onChange(e);
          }}
          ref={inputRef}
        />
        {showSuggestions ? (
          <>
            <SuggestionList
              renderSuggestions={renderSuggestions}
              setShowSuggestions={setShowSuggestions}
              setNavbarSearch={setNavbarSearch}
            />
          </>
        ) : null}
      </AutoCompleteWrapper>
      <Overlay show={showSuggestions} />
    </>
  );
};

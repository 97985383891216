import styled from 'styled-components';

export const MenuWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem;
`;

export const PDFWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 85%;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

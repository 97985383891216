import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Main from './Main';

import DocumentProvider from './context/DocumentProvider';

const App = ({
  ehrRecord,
  requestPatientRecord,
  isNewRecordAvailable,
  getPatientAttachment,
  exportCDAAsPDF,
  exportCDAAsPDFLoading,
  setExportCDAAsPDFLoading
}) => {
  return (
    <Router>
      <DocumentProvider>
        <AnimatePresence>
          <Switch>
            <Route
              exact={ehrRecord ? false : true}
              path="/"
              render={() => (
                <Main
                  ehrRecord={ehrRecord}
                  requestPatientRecord={requestPatientRecord}
                  isNewRecordAvailable={isNewRecordAvailable}
                  getPatientAttachment={getPatientAttachment}
                  exportCDAAsPDF={exportCDAAsPDF}
                  exportCDAAsPDFLoading={exportCDAAsPDFLoading}
                  setExportCDAAsPDFLoading={setExportCDAAsPDFLoading}
                />
              )}
            />
          </Switch>
        </AnimatePresence>
      </DocumentProvider>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';

import { Button } from '../components';

import NavbarSearch from './NavbarSearch';
import { NavbarWrapper, NavbarContent } from './Layout.style';

const Navbar = ({
  expanded,
  toggleExpand,
  searchData,
  toggle,
  attachmentData
}) => {
  const [navbarSearch, setNavbarSearch] = useState(false);

  return (
    <>
      <NavbarWrapper expanded={expanded}>
        {!navbarSearch ? (
          <NavbarContent>
            <div className="navbar-left">
              <MenuIcon onClick={toggleExpand} />
            </div>
            <div
              className="navbar-center"
              onClick={() => setNavbarSearch(true)}
            >
              <span>Search...</span>
            </div>
            <div className="navbar-right">
              {attachmentData.length ? (
                <Button text="Attachments" onClick={toggle} />
              ) : null}
            </div>
          </NavbarContent>
        ) : (
          <NavbarSearch
            searchData={searchData}
            setNavbarSearch={setNavbarSearch}
            toggle={toggle}
          />
        )}
      </NavbarWrapper>
    </>
  );
};

export default Navbar;

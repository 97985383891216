import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import {
  X,
  ChevronLeft,
  Download,
  ChevronDown,
  ChevronUp,
  RotateCw
} from 'react-feather';
import queryString from 'query-string';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { pdf } from '@react-pdf/renderer';

import { Icon, Button, CdaPDF } from '../components';
import useWindowSize from '../lib/utils/hooks/useWindowSize';
import useOnClickOutside from '../lib/utils/hooks/useOnClickOutside';
import { exportRows } from '../lib/utils/helper/export';
import { DocumentContext } from '../context/DocumentProvider';
import { SidebarWrapper } from './Layout.style';

import logo from '../assets/images/logo/mdportals-logo.png';
import logoSmall from '../assets/images/logo/mdportals-logo-small.png';

dayjs.extend(relativeTime);

const Sidebar = ({
  expanded,
  setExpanded,
  documentTitle,
  rowsToExport,
  resetRowsSelection,
  rawDocument,
  currentDocument,
  ehrRecord,
  exportCDAAsPDF,
  exportCDAAsPDFLoading,
  setExportCDAAsPDFLoading
}) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const sidebarRef = useRef(null);
  const [menuHover, setMenuHover] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);

  const [exportAllButtonExpanded, setExportAllButtonExpanded] = useState(false);
  const [rowsButtonExpanded, setRowsButtonExpanded] = useState(false);

  // const exportCDAAsPDFLoading = {
  //   1: false,
  //   2: false,
  //   3: false,
  //   4: false
  // };

  const exportAsPDF = async (config, id) => {
    setExportCDAAsPDFLoading({ ...exportCDAAsPDFLoading, [id]: true });

    const conf = config ? config : {};

    try {
      await exportCDAAsPDF(conf);
      setExportCDAAsPDFLoading({ ...exportCDAAsPDFLoading, [id]: false });
    } catch (error) {
      console.log('[exportAsPDF] error: ', error);
      setExportCDAAsPDFLoading({ ...exportCDAAsPDFLoading, [id]: false });
    }
  };

  const closeMobileMenu = useCallback(() => {
    if (width < 1199) {
      setExpanded(false);
    }
  }, [width, setExpanded]);

  const downloadRecord = () => {
    const patientName = currentDocument.header.find(
      (el) => el.key === 'Patient'
    ).value;
    const fileName = patientName;

    const blob = new Blob([rawDocument], { type: 'text/xml' });

    fileDownload(blob, `${fileName}.xml`);
  };

  const downloadRowsAsXML = (rawDocument, rowsToExport) => {
    let exportedRowsString;

    exportedRowsString = exportRows(rawDocument, rowsToExport);

    const patientName = currentDocument.header.find(
      (el) => el.key === 'Patient'
    ).value;

    const fileName = `${patientName}`;

    const blob = new Blob([exportedRowsString], { type: 'text/xml' });

    fileDownload(blob, `${fileName}.xml`);
  };

  const downloadRowsAsPDF = async (rawDocument, rowsToExport) => {
    console.log('[downloadRowsAsPDF] rowsToExport: ', rowsToExport);

    const blob = await pdf(
      <CdaPDF
        documentHeader={currentDocument.header}
        exportedRows={rowsToExport}
      />
    ).toBlob();

    const patientName = currentDocument.header.find(
      (el) => el.key === 'Patient'
    ).value;

    const fileName = `${patientName}`;

    fileDownload(blob, `${fileName}.pdf`);
  };

  const rowsSum = (arr) => {
    let sum = 0;

    arr.forEach((el) => (sum += el.rows.length));

    return sum;
  };

  useEffect(() => {
    closeMobileMenu();

    const { attachment } = queryString.parse(history.location.search);

    if (attachment) {
      setIsAttachment(true);
    } else {
      setIsAttachment(false);
    }
  }, [width, closeMobileMenu]);

  useOnClickOutside(sidebarRef, () => closeMobileMenu());

  const tocData = currentDocument.body.map((section) => section.title);

  return (
    <>
      <SidebarWrapper
        expanded={expanded}
        menuHover={menuHover}
        onMouseEnter={() => setMenuHover(true)}
        onMouseLeave={() => setMenuHover(false)}
        className={expanded ? 'open' : ''}
        ref={sidebarRef}
      >
        <div className="sidebar-header">
          {isAttachment ? (
            <>
              {expanded ? (
                <Button
                  text="Go Back"
                  className="flex"
                  onClick={() => window.history.back()}
                  icon={<ChevronLeft />}
                />
              ) : menuHover ? (
                <Button
                  text="Go Back"
                  className="flex"
                  onClick={() => window.history.back()}
                  icon={<ChevronLeft />}
                />
              ) : (
                <img src={logoSmall} alt="MDPortals Logo" />
              )}
            </>
          ) : (
            <img
              src={expanded ? logo : menuHover ? logo : logoSmall}
              alt="MDPortals Logo"
              onClick={() => history.push('/')}
            />
          )}
          <X onClick={() => setExpanded(false)} />
        </div>

        {expanded || menuHover ? (
          <>
            <div className="record-data">
              {documentTitle ? (
                <div className="badge">{documentTitle}</div>
              ) : null}
              <>
                {ehrRecord && ehrRecord.dataAsOf ? (
                  <div className="badge green">
                    Data as of {ehrRecord.dataAsOf}
                    {/* Data as of 01/01/2020 */}
                  </div>
                ) : null}

                <>
                  <Button
                    text={'Export all rows'}
                    className="small flex outline"
                    icon={
                      exportAllButtonExpanded ? <ChevronUp /> : <ChevronDown />
                    }
                    onClick={() =>
                      setExportAllButtonExpanded(!exportAllButtonExpanded)
                    }
                  />
                  {exportAllButtonExpanded ? (
                    <>
                      {ehrRecord ? (
                        <>
                          <Button
                            text={'as PDF'}
                            className="small outline flex sub"
                            onClick={() => exportAsPDF(null, 1)}
                            icon={
                              exportCDAAsPDFLoading &&
                              exportCDAAsPDFLoading[1] ? (
                                <RotateCw
                                  style={{
                                    animation: `spin 1.5s linear infinite`
                                  }}
                                />
                              ) : (
                                <Download />
                              )
                            }
                            disabled={
                              exportCDAAsPDFLoading &&
                              Object.keys(exportCDAAsPDFLoading).find(
                                (i) => exportCDAAsPDFLoading[i] === true
                              )
                            }
                          />
                          <Button
                            text={'as PDF with RAF'}
                            className="small outline flex sub"
                            onClick={() => exportAsPDF({ withRaf: true }, 2)}
                            icon={
                              exportCDAAsPDFLoading &&
                              exportCDAAsPDFLoading[2] ? (
                                <RotateCw
                                  style={{
                                    animation: `spin 1.5s linear infinite`
                                  }}
                                />
                              ) : (
                                <Download />
                              )
                            }
                            disabled={
                              exportCDAAsPDFLoading &&
                              Object.keys(exportCDAAsPDFLoading).find(
                                (i) => exportCDAAsPDFLoading[i] === true
                              )
                            }
                          />
                        </>
                      ) : null}
                      <Button
                        text={'as CCD XML'}
                        className="small outline flex sub"
                        onClick={() => downloadRecord()}
                        icon={<Download />}
                        disabled={
                          exportCDAAsPDFLoading &&
                          Object.keys(exportCDAAsPDFLoading).find(
                            (i) => exportCDAAsPDFLoading[i] === true
                          )
                        }
                      />
                    </>
                  ) : null}
                </>
                {ehrRecord && ehrRecord.hccCompendiumUrl ? (
                  <Button
                    text="View Compendium"
                    className="small outline lightblue"
                    onClick={() =>
                      window.open(ehrRecord.hccCompendiumUrl, '_blank')
                    }
                  />
                ) : null}
              </>
            </div>
          </>
        ) : (
          <h5>...</h5>
        )}
        {rowsToExport && rowsToExport.length ? (
          <div className="export-buttons">
            {expanded || menuHover ? (
              <>
                <Button
                  text={`Export ${rowsSum(rowsToExport)} ${
                    rowsSum(rowsToExport) > 1 ? 'rows' : 'row'
                  }`}
                  icon={rowsButtonExpanded ? <ChevronUp /> : <ChevronDown />}
                  className="flex grey small"
                  onClick={() => setRowsButtonExpanded(!rowsButtonExpanded)}
                />
                {rowsButtonExpanded ? (
                  <>
                    {ehrRecord ? (
                      <>
                        <Button
                          text={`as PDF`}
                          icon={
                            exportCDAAsPDFLoading &&
                            exportCDAAsPDFLoading[3] ? (
                              <RotateCw
                                style={{
                                  animation: `spin 1.5s linear infinite`
                                }}
                              />
                            ) : (
                              <Download />
                            )
                          }
                          className="flex grey small sub"
                          onClick={() => exportAsPDF({ rows: rowsToExport }, 3)}
                          disabled={
                            exportCDAAsPDFLoading &&
                            Object.keys(exportCDAAsPDFLoading).find(
                              (i) => exportCDAAsPDFLoading[i] === true
                            )
                          }
                        />
                        <Button
                          text={`as PDF with RAF`}
                          icon={
                            exportCDAAsPDFLoading &&
                            exportCDAAsPDFLoading[4] ? (
                              <RotateCw
                                style={{
                                  animation: `spin 1.5s linear infinite`
                                }}
                              />
                            ) : (
                              <Download />
                            )
                          }
                          className="flex grey small sub"
                          onClick={() =>
                            exportAsPDF(
                              { withRaf: true, rows: rowsToExport },
                              4
                            )
                          }
                          disabled={
                            exportCDAAsPDFLoading &&
                            Object.keys(exportCDAAsPDFLoading).find(
                              (i) => exportCDAAsPDFLoading[i] === true
                            )
                          }
                        />
                      </>
                    ) : null}
                    <Button
                      text={`as CCD XML`}
                      icon={<Download />}
                      className="flex grey small sub"
                      onClick={() =>
                        downloadRowsAsXML(rawDocument, rowsToExport)
                      }
                      disabled={
                        exportCDAAsPDFLoading &&
                        Object.keys(exportCDAAsPDFLoading).find(
                          (i) => exportCDAAsPDFLoading[i] === true
                        )
                      }
                    />
                  </>
                ) : null}
                <Button
                  text={`Reset row selection`}
                  className="flex grey small reset-button"
                  onClick={() => {
                    resetRowsSelection();
                    setRowsButtonExpanded(false);
                  }}
                />
              </>
            ) : (
              <h5>...</h5>
            )}
          </div>
        ) : null}
        <div className={`sidebar-body ${ehrRecord ? '' : 'full'}`}>
          {expanded || menuHover ? <h5>Table of Contents</h5> : <h5>...</h5>}
          <ul>
            {tocData &&
              tocData.map((item, i) => (
                <li key={i}>
                  <Link
                    to={item}
                    activeClass="active"
                    offset={-250}
                    smooth={true}
                    spy={true}
                    isDynamic={true}
                    onClick={() => closeMobileMenu()}
                  >
                    <span className="nav-icon">
                      <Icon title={item} />
                    </span>
                    <span className="nav-text">{item}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;

import styled from 'styled-components';

export const FileUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 90vh;

  .upload-container {
    padding: 5rem;
    outline: 2px dashed #7367f0;
    cursor: pointer;
    max-width: 600px;
  }

  .sample-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-left: 1rem;
    }
  }
`;

export const Logo = styled.img`
  display: flex;
  margin: 2rem auto;
  width: 200px;
  height: auto;
`;

import React, { useState } from 'react';

import { AttachedDocs } from '../components';
import { useModal, Modal } from '../lib/utils/hooks/useModal';
import { Content } from './Layout.style';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = ({
  children,
  currentDocument,
  requestPatientRecord,
  isNewRecordAvailable,
  rowsToExport,
  resetRowsSelection,
  rawDocument,
  ehrRecord,
  documentTitle,
  exportCDAAsPDF,
  exportCDAAsPDFLoading,
  setExportCDAAsPDFLoading
}) => {
  const { toggle, isShowing } = useModal();
  const [expanded, setExpanded] = useState(true);

  const { attachments, searchData } = currentDocument;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <>
        <Navbar
          toggleExpand={toggleExpand}
          expanded={expanded}
          attachmentData={attachments}
          searchData={searchData}
          toggle={toggle}
        />
        <Sidebar
          expanded={expanded}
          currentDocument={currentDocument}
          setExpanded={setExpanded}
          documentTitle={documentTitle}
          requestPatientRecord={requestPatientRecord}
          isNewRecordAvailable={isNewRecordAvailable}
          rowsToExport={rowsToExport}
          resetRowsSelection={resetRowsSelection}
          rawDocument={rawDocument}
          ehrRecord={ehrRecord}
          exportCDAAsPDF={exportCDAAsPDF}
          exportCDAAsPDFLoading={exportCDAAsPDFLoading}
          setExportCDAAsPDFLoading={setExportCDAAsPDFLoading}
        />
        <Content expanded={expanded}>{children}</Content>
      </>
      <Footer />
      <>
        <Modal isShowing={isShowing} hide={toggle}>
          <AttachedDocs attachmentData={attachments} />
        </Modal>
      </>
    </>
  );
};

export default Layout;

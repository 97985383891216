import React from 'react';
import { Search as SearchIcon, X as CloseIcon } from 'react-feather';

import { AutoComplete } from '../components';

import { NavbarSearchWrapper } from './Layout.style';

const NavbarSearch = ({ setNavbarSearch, searchData, toggle }) => {
  return (
    <>
      <NavbarSearchWrapper>
        <div className="search-input-icon">
          <SearchIcon />
        </div>
        <AutoComplete
          searchData={searchData}
          setNavbarSearch={setNavbarSearch}
          toggle={toggle}
        />
        <div className="search-input-close">
          <CloseIcon onClick={() => setNavbarSearch(false)} />
        </div>
      </NavbarSearchWrapper>
    </>
  );
};

export default NavbarSearch;

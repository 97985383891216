import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { scroller } from 'react-scroll';
import queryString from 'query-string';

import { DocumentHeader } from '../../components';
import { FileUpload } from '../../container';
import Layout from '../../layout';
import { DocumentContext } from '../../context/DocumentProvider';

import BodySection from './BodySection';

import GlobalStyles from '../../styles/Global.style';

export const CDAMainViewer = ({
  ehrRecord,
  requestPatientRecord,
  isNewRecordAvailable,
  exportCDAAsPDF,
  exportCDAAsPDFLoading,
  setExportCDAAsPDFLoading
}) => {
  const history = useHistory();

  const [rowsToExport, setRowsToExport] = useState([]);
  const [resetRows, setResetRows] = useState(false);

  const { currentDocument, rawDocument } = useContext(DocumentContext);

  const params = queryString.parse(history.location.search);
  console.log('params:::: ', params);

  useEffect(() => {
    if (params.section) {
      scroller.scrollTo(params.section, { smooth: true, duration: 1000 });
    } else if (params.rowId) {
      scroller.scrollTo(params.rowId, {
        smooth: true,
        duration: 1000,
        offset: -250
      });
    }

    // if (params.search) {
    //   console.log('params.search: ', params.search);

    //   const searchValue = params.search;

    //   let filteredData = {};

    //   currentDocument.searchData.forEach((suggestion) => {
    //     filteredData[suggestion.groupTitle] = [];

    //     const sortData = suggestion.data
    //       .filter((i) => {
    //         if (typeof i === 'string') {
    //           const startCondition = i
    //               .toLowerCase()
    //               .startsWith(searchValue.toLowerCase()),
    //             includeCondition = i
    //               .toLowerCase()
    //               .includes(searchValue.toLowerCase());

    //           if (startCondition) {
    //             return startCondition;
    //           } else if (!startCondition && includeCondition) {
    //             return includeCondition;
    //           } else {
    //             return null;
    //           }
    //         }

    //         return null;
    //       })
    //       .slice(0, 1);

    //     filteredData[suggestion.groupTitle].push(...sortData);
    //   });

    //   Object.keys(filteredData).some((category, i) => {
    //     if (filteredData[category].length) {
    //       setSearchParams(category, filteredData[category][0]);
    //       scroller.scrollTo(category, { smooth: true, duration: 1000 });
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // }
  }, [currentDocument, history.location.search]);

  if (!currentDocument) {
    return <FileUpload ehrRecord={ehrRecord} />;
  }

  const { header, body } = currentDocument;

  const resetRowsSelection = () => {
    setRowsToExport([]);

    history.replace({
      search: ''
    });
    setResetRows(true);

    setTimeout(() => {
      setResetRows(false);
    }, 500);
  };

  return (
    <>
      <GlobalStyles />
      <motion.div>
        <Layout
          currentDocument={currentDocument}
          requestPatientRecord={requestPatientRecord}
          isNewRecordAvailable={isNewRecordAvailable}
          rowsToExport={rowsToExport}
          resetRowsSelection={resetRowsSelection}
          rawDocument={rawDocument}
          ehrRecord={ehrRecord}
          exportCDAAsPDF={exportCDAAsPDF}
          exportCDAAsPDFLoading={exportCDAAsPDFLoading}
          setExportCDAAsPDFLoading={setExportCDAAsPDFLoading}
          documentTitle={params.title}
        >
          <DocumentHeader documentHeaderData={header} />
          {body &&
            body.map((section, i) => (
              <BodySection
                key={i}
                section={section}
                setRowsToExport={setRowsToExport}
                resetRows={resetRows}
                currentDocument={currentDocument}
                rawDocument={rawDocument}
              />
            ))}
        </Layout>
      </motion.div>
    </>
  );
};

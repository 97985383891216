import React from 'react';

import { SpinnerWrapper } from './Spinner.style';

export const Spinner = () => {
  return (
    <SpinnerWrapper>
      <div className="loading"></div>
    </SpinnerWrapper>
  );
};

const pullAttachments = ($) => {
  let contents = [];

  if (!$) {
    return;
  }

  $('text[representation="B64"], value[representation="B64"]').each((i, el) => {
    let data, mediaType, title, id;

    id = $(el).attr('ID') ? $(el).attr('ID') : i.toString();
    mediaType = $(el).attr('mediaType');

    const isRTF = mediaType === 'text/rtf';

    title = $(el).attr('name');
    title = title
      ? title
      : isRTF
      ? `Attachment${1 + i}.rtf`
      : `Attachment${1 + i}`;

    data = $(el).text().trim();

    contents.push({ id, title, mediaType, data });
  });

  return contents;
};

export default pullAttachments;

import React from 'react';
import {
  Circle,
  BookOpen,
  DollarSign,
  File,
  AlertCircle,
  Copy,
  Users,
  AlertTriangle,
  Shield,
  Heart,
  FileText,
  GitBranch,
  Search,
  Book,
  Activity,
  User,
  Calendar,
  Clipboard,
  Mail,
  Compass,
  Edit,
  CheckCircle,
  Clock
} from 'react-feather';
import PDF from '../../assets/images/file/pdf.svg';
import XML from '../../assets/images/file/xml.svg';
import HTML from '../../assets/images/file/html.svg';

const hasWords = (verifiedWord, wordList) => {
  for (const word of wordList) {
    if (verifiedWord.toLowerCase().includes(word)) {
      return true;
    }
  }

  return false;
};

export const Icon = ({ title }) => {
  switch (true) {
    case hasWords(title, ['summary']):
      return <BookOpen size={15} />;

    case hasWords(title, ['payers', 'insurance']):
      return <DollarSign size={15} />;

    case hasWords(title, ['directive', 'file', 'attach', 'document']):
      return <File size={15} />;

    case hasWords(title, ['function']):
      return <Activity size={15} />;

    case hasWords(title, ['categor', 'hierar', 'section']):
      return <Copy size={15} />;

    case hasWords(title, ['problem']):
      return <AlertCircle size={15} />;

    case hasWords(title, ['family', 'social', 'organization']):
      return <Users size={15} />;

    case hasWords(title, ['alert', 'allergy', 'allergie']):
      return <AlertTriangle size={15} />;

    case hasWords(title, ['medic', 'immun']):
      return <Shield size={15} />;

    case hasWords(title, ['vital']):
      return <Heart size={15} />;

    case hasWords(title, ['result']):
      return <FileText size={15} />;

    case hasWords(title, ['procedure']):
      return <GitBranch size={15} />;

    case hasWords(title, ['encounter', 'labor']):
      return <Search size={15} />;

    case hasWords(title, ['plan']):
      return <Book size={15} />;

    case hasWords(title, ['patient']):
      return <User size={15} />;

    case hasWords(title, ['date']):
      return <Calendar size={15} />;

    case hasWords(title, ['signed']):
      return <Clipboard size={15} />;

    case hasWords(title, ['contact']):
      return <Mail size={15} />;

    case hasWords(title, ['sex']):
      return <Compass size={15} />;

    case hasWords(title, ['author']):
      return <Edit size={15} />;

    case hasWords(title, ['authenticator']):
      return <CheckCircle size={15} />;

    case hasWords(title, ['created at']):
      return <Clock size={15} />;

    case hasWords(title, ['application/pdf']):
      return <img src={PDF} alt="" />;

    case hasWords(title, ['text/html']):
      return <img src={HTML} alt="" />;

    case hasWords(title, ['text/xml']):
      return <img src={XML} alt="" />;

    default:
      return <Circle size={15} />;
  }
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'react-feather';
import rtfToHTML from '@iarna/rtf-to-html';
import InnerHTML from 'dangerously-set-html-content';

import { Button } from '../../components';

import { RTFWrapper, MenuWrapper } from './RTFViewer.style';

import useScrollToTop from '../../lib/utils/hooks/useScrollToTop';

export const RTFViewer = ({ data }) => {
  const [htmlContent, setHtmlContent] = useState(null);

  useScrollToTop();

  const history = useHistory();

  if (!data) {
    history.push({ pathname: '/', state: { from: history.location.pathname } });
  }

  const outputTemplate = (doc, defaults, content) => {
    return `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8">
    </head>
    <body>
      ${content.replace(/\n/, '\n    ')}
    </body>
  </html>`;
  };

  useEffect(() => {
    console.log('[useEffect] data: ', data);

    const decodedRTF = window.atob(data);
    console.log('[useEffect] decodedRTF: ', decodedRTF);

    rtfToHTML.fromString(
      decodedRTF,
      {
        template: outputTemplate
      },
      (err, html) => {
        if (err) console.log(err);

        setHtmlContent(html);
      }
    );
  }, []);

  return (
    <motion.div>
      <MenuWrapper>
        <Button
          text="Go Back"
          icon={<ChevronLeft />}
          onClick={() => {
            window.history.back();
          }}
        />
      </MenuWrapper>
      <RTFWrapper>
        {htmlContent ? <InnerHTML html={htmlContent} /> : null}
      </RTFWrapper>
    </motion.div>
  );
};
